.input-color {
  width: 100%;
  height: 30px;
  background-color: #e4e4e4;
  border: 2px solid #e4e4e4;
  border-radius: 6px;
  position: relative;
  cursor: pointer;

  .color {
    background-color: #fff;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=');
    background-size: 30px 30px;
    background-position: 0 0, 15px 15px;
    background-clip: content-box;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .fill {
      left: 0;
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

.picker-holder {
  width: 100%;
  margin-top: 10px;
  display: none;

  &.visible {
    display: inline-block;
  }
}
